body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #001233 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-bar {
  background-color: #ff595a;
}

.max-size-img {           /*dims: 408 by 456px*/
  min-width: 180px;
  max-width: 600px;

  margin-left: 1rem;

  object-fit: contain;
}

#social {
  margin-left: 1rem;
}

.bio {
  display: block;
  text-align: left;
  margin-right: 1.5rem;
}

.responsive-text {
  font-size: 1.5rem;      /* Initial font size */
  margin-left: 8rem;
}

.toast-spacing {
  margin-left: .7rem;
}

.toast-body {
  font-size: 16px;
}

@media (max-width: 977px) {
  .responsive-text {
    margin-left: 7rem;
  }
}

@media (max-width: 768px) {
  .responsive-text {
    font-size: .9rem;     /* Font size for even smaller screens */
    margin-left: 4rem;
  }
}

@media (max-width: 384px) {
  .responsive-text {      /* Font size for even smaller screens */
    margin-left: 2rem;
    font-size: .7rem;
  }
  .bio {
    margin-right: 1rem;
  }
  .toast-body {
    font-size: 12px;
  }
  .toast-spacing {
    margin-left: 0;
  }
  #social {
    margin-left: .7rem;
  }
  div.col h1 {
    font-size: 1.3rem;
  }
}
@media (max-width: 425px) {
  div.col h1 {
    font-size: 1rem;
  }
}

@media (max-width: 330px) {
  .responsive-text {
    margin-left: 1.5rem;
    font-size: .6rem;
  }
  div.col h1 {
    font-size: .8rem;
  }
}
